
































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import QuestionsLayout from '@/layouts/question/QuestionsLayout.vue';
import QuestionFormTemplate from '@/components/question/QuestionFormTemplate.vue';
import { DropdownItem } from '@/models/dropdown';
import { GeneralMCQBody, UpdateQuestionBankEssayQuestionRequest, UpdateQuestionBankQuestions, UpdateQuestionBankQuestionForAxios } from '@/models/question_banks';
import { FormStores } from '@/store/forms';
import { SubjectStores } from '@/store/subjects';
import { OneOrMultipleAnswers, NumberChoices, BasicYesOrNo, QuestionTypeNames, VideoFileSizeLimit } from '@/utils/constants';
import { QuestionStores } from '@/store/questions';
import { ChapterStores } from '@/store/chapters';
import { QuestionBankStores } from '@/store/question_banks';
import { QuestionBankQuestionDetailItemResponse } from '@/models/questions';
import { uploadAttachment } from '@/utils/attachments';
@Component({
    components: { Page, QuestionsLayout, QuestionFormTemplate },
})
export default class EditQuestion extends Vue {
    subjectListStore = SubjectStores.list;
    levelListStore = FormStores.list;
    chapterListStore = ChapterStores.list;
    questionBankListStore = QuestionBankStores.list;
    // Dropdowns
    questionSubjectList: DropdownItem[] = [];
    questionLevelList: DropdownItem[] = [];
    questionBankList: DropdownItem[] = [];
    questionChapterList: DropdownItem[] = [];
    answerOptionList = OneOrMultipleAnswers;
    answerDisplayList = NumberChoices;
    standardInstructionList = BasicYesOrNo;
    currentFormTemplate: UpdateQuestionBankQuestions | null = null;
    //Store
    questionDetailStore = QuestionStores.detail;
    questionStateStore = QuestionStores.state;
    // Form Selection Variable
    selectedQuestionType: QuestionTypeNames | string = '';

    get title() {
        return `Edit Question`;
    }

    get subjects() {
        return this.questionSubjectList;
    }

    get levels() {
        return this.questionLevelList;
    }

    get questionBanks() {
        return this.questionBankList;
    }

    get chapters() {
        return this.questionChapterList;
    }

    get answerOptions() {
        return this.answerOptionList;
    }

    get answerDisplays() {
        return this.answerDisplayList;
    }

    get standardInstructions() {
        return this.standardInstructionList;
    }

    get showTemplate() {
        return this.subjects.length > 0 && 
            this.levels.length  > 0 && 
            this.questionBanks.length  > 0 && 
            this.chapters.length  > 0;
    }

    mounted() {
        this.subjectListStore.retrieveSubjects({});
        this.questionDetailStore.retrieveQuestionBankQuestionById(Number(this.$route.params.id));
    }

    @Watch(`questionDetailStore.fetchedQuestionResponse`)
    updateQuestionData() {
        if (this.questionDetailStore.fetchedQuestionResponse ) {
            this.retrieveLevels(this.questionDetailStore.fetchedQuestionResponse.subjectId);
            this.retrieveQuestionBankListByFormId(this.questionDetailStore.fetchedQuestionResponse.formId);
            this.retrieveChaptersByQuestionBankId((this.questionDetailStore.fetchedQuestionResponse as QuestionBankQuestionDetailItemResponse ).questionBankId);
            this.selectedQuestionType = this.questionDetailStore.fetchedQuestionResponse.qType;
            switch (this.questionDetailStore.fetchedQuestionResponse.qType) {
                case QuestionTypeNames.mcq : {
                    this.currentFormTemplate = {
                        id: Number(this.$route.params.id),
                        availability: this.questionDetailStore.fetchedQuestionResponse.availability === 1 ? true : false,
                        questionType: this.questionDetailStore.fetchedQuestionResponse.qType,
                        subjectId: this.questionDetailStore.fetchedQuestionResponse.subjectId,
                        formId: this.questionDetailStore.fetchedQuestionResponse.formId,
                        questionBankId: (this.questionDetailStore.fetchedQuestionResponse as QuestionBankQuestionDetailItemResponse ).questionBankId,
                        chapterId: (this.questionDetailStore.fetchedQuestionResponse as QuestionBankQuestionDetailItemResponse ).chapterId,
                        questionName: this.questionDetailStore.fetchedQuestionResponse.name,
                        questionText: this.questionDetailStore.fetchedQuestionResponse.questionText,
                        defaultMark: this.questionDetailStore.fetchedQuestionResponse.defaultMark,
                        generalFeedback: this.questionDetailStore.fetchedQuestionResponse.generalFeedback,
                        areChoicesShuffled: this.questionDetailStore.fetchedQuestionResponse.shuffleAnswers === 1 ? true : false,
                        hasMultipleAnswers: this.questionDetailStore.fetchedQuestionResponse.single === 1 ? false : true,
                        choiceNumberType: this.questionDetailStore.fetchedQuestionResponse.answerNumbering,
                        showStandardInstructions: true,
                        choices: [],
                        questionAsset: this.questionDetailStore.fetchedQuestionResponse.videoThumbnail ? this.questionDetailStore.fetchedQuestionResponse.videoUrl : this.questionDetailStore.fetchedQuestionResponse.imageUrl ? this.questionDetailStore.fetchedQuestionResponse.imageUrl : ``,
                    };
                    // Repopulate
                    const anyOptions: GeneralMCQBody[] = [];
                    // we know that the lengths are the same
                    for (let index = 0; index < this.questionDetailStore.fetchedQuestionResponse.answer.length; index++) {
                        const item: GeneralMCQBody = {
                            id: index,
                            choiceText: this.questionDetailStore.fetchedQuestionResponse.answer[index],
                            feedbackText: this.questionDetailStore.fetchedQuestionResponse!.feedback[index],
                            isAnswerValid: this.questionDetailStore.fetchedQuestionResponse!.fraction[index] > 0 ? true : false,
                        };
                        anyOptions.push(item);
                    }
                    this.currentFormTemplate.choices = anyOptions;
                    break;
                }
                case QuestionTypeNames.essay: {
                    // Repopulate
                    this.currentFormTemplate = {
                        id: Number(this.$route.params.id),
                        questionType: this.questionDetailStore.fetchedQuestionResponse.qType,
                        availability: this.questionDetailStore.fetchedQuestionResponse.availability === 1 ? true : false,
                        subjectId: this.questionDetailStore.fetchedQuestionResponse.subjectId,
                        formId: this.questionDetailStore.fetchedQuestionResponse.formId,
                        questionBankId: (this.questionDetailStore.fetchedQuestionResponse as QuestionBankQuestionDetailItemResponse ).questionBankId,
                        chapterId: (this.questionDetailStore.fetchedQuestionResponse as QuestionBankQuestionDetailItemResponse ).chapterId,
                        questionName: this.questionDetailStore.fetchedQuestionResponse.name,
                        questionText: this.questionDetailStore.fetchedQuestionResponse.questionText,
                        defaultMark: this.questionDetailStore.fetchedQuestionResponse.defaultMark,
                        generalFeedback: this.questionDetailStore.fetchedQuestionResponse.generalFeedback,
                        answerText: this.questionDetailStore.fetchedQuestionResponse.answer[0],
                        requireText: this.questionDetailStore.fetchedQuestionResponse.responseRequired.toString(),
                        questionAsset: this.questionDetailStore.fetchedQuestionResponse.videoThumbnail ? this.questionDetailStore.fetchedQuestionResponse.videoUrl : this.questionDetailStore.fetchedQuestionResponse.imageUrl ? this.questionDetailStore.fetchedQuestionResponse.imageUrl : ``,
                    } as UpdateQuestionBankEssayQuestionRequest;
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    retrieveLevels(subjectId: number) {
        this.levelListStore.retrieveForms({subjectid: subjectId});
    }

    retrieveQuestionBankListByFormId(formId: number) {
        this.questionBankListStore.retrieveQuestionBanks({ formid: formId });
    }

    retrieveChaptersByQuestionBankId(questionBankId: number) {
        if (this.questionBankListStore.response) {
            // Update Question Type
            const index = this.questionBankListStore.response.objects.findIndex((data) => {
                return data.id === questionBankId
            });
            if (index > -1) {
                this.selectedQuestionType = this.questionBankListStore.response!.objects[index].questionType;
            }
        }
        this.chapterListStore.retrieveChaptersByQuestionBankId(questionBankId);
    }

    @Watch(`subjectListStore.response`)
    updateSubjectDropdown() {
        if (this.subjectListStore.response) {
            this.questionSubjectList = [];
            this.subjectListStore.response.objects.forEach((item) => {
                this.questionSubjectList.push({
                    text: item.subjectName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`levelListStore.response`)
    updateFormDropdown() {
        if (this.levelListStore.response) {
            this.questionLevelList = [];
            this.levelListStore.response.objects.forEach((item) => {
                this.questionLevelList.push({
                    text: item.formName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`questionBankListStore.response`)
    updateQuestionBanks() {
        if (this.questionBankListStore.response) {
            this.questionBankList = [];
            this.questionBankListStore.response.objects.forEach((data) => {
                if (data.questionType === QuestionTypeNames.mcq) {
                    this.questionBankList.push({
                        text: `${data.name} (Multiple Choice)`,
                        value: data.id.toString(),
                        uniqueKey: `${data.name}_${data.id}`,
                    });
                }

                if (data.questionType === QuestionTypeNames.essay) {
                    this.questionBankList.push({
                        text: `${data.name} (Essay)`,
                        value: data.id.toString(),
                        uniqueKey: `${data.name}_${data.id}`,
                    });
                }
            });
        }
    }

    @Watch(`chapterListStore.response`)
    updateChapterDropdown() {
        if (this.chapterListStore.response) {
            this.questionChapterList = [];
            this.chapterListStore.response.objects.forEach((item) => {
                this.questionChapterList.push({
                    text: item.name,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    redirectToQuestionManagement() {
        this.$router.push({
            path: `/question_banks`
        });
    }

    async updateCurrentSource(data: UpdateQuestionBankQuestions) {
        this.currentFormTemplate = {
            ...data
        };
        
        // Format data to match API
        // General Section
        let input: Partial<UpdateQuestionBankQuestionForAxios> = {
            id: Number(this.$route.params.id),
            availability: data.availability ? 1 : 0,
            subjectId: data.subjectId,
            formId: data.formId,
            questionBankId: data.questionBankId,
            chapterId: data.chapterId,
            qType: data.questionType,
            name: data.questionName,
            questionText: data.questionText,
            defaultMark: data.defaultMark,
            generalFeedback: data.generalFeedback,
            imageUrl: this.questionDetailStore.fetchedQuestionResponse?.imageUrl,
            videoUrl: this.questionDetailStore.fetchedQuestionResponse?.videoUrl,
            videoThumbnail: this.questionDetailStore.fetchedQuestionResponse?.videoThumbnail,
        };

        // If questionAsset is a video type
        if (data.questionAsset && 
            typeof data.questionAsset !== 'string' &&
            data.questionAsset.originalFile && 
            data.questionAsset.originalFile.type.includes(`video/`) &&
            data.questionAsset.thumbnail
        ) {
            const thumbnailPromise = uploadAttachment(
                data.questionAsset.thumbnail,
                data.questionAsset.thumbnail?.name.split(`.`)[0],
                `thumbnail`
            );
            const videoPromise = uploadAttachment(
                data.questionAsset.originalFile,
                data.questionAsset.originalFile.name,
                `video`,
                VideoFileSizeLimit.questions
            );
            const promises = await Promise.all([thumbnailPromise, videoPromise]);
            input.videoThumbnail = promises[0];
            input.videoUrl = promises[1];
            input.imageUrl = '';
        }

        if (data.questionAsset && 
            typeof data.questionAsset !== 'string' &&
            data.questionAsset.originalFile && 
            data.questionAsset.originalFile.type.includes(`image/`)
        ) {
            const image = await uploadAttachment(
                data.questionAsset.originalFile,
                data.questionAsset.originalFile?.name,
                `thumbnail`
            );
            input.imageUrl = image;
            input.videoThumbnail = '';
            input.videoUrl = '';
        }

        if (
            data.questionAsset && 
            typeof data.questionAsset !== 'string' &&
            !data.questionAsset.thumbnail &&
            !data.questionAsset.originalFile
        ) { 
            input.imageUrl = '';
            input.videoUrl = '';
            input.videoThumbnail = '';
        }
        // TODO: Set approved to be dynamic
        // Mcq
    
        if ('hasMultipleAnswers' in data) {
            input.single = data.hasMultipleAnswers ? 0 : 1; // 1 means true, 0 means false
            input.shuffleAnswers = data.areChoicesShuffled ? 1 : 0
            input.answerNumbering = data.choiceNumberType;
            input.approved = data.hasMultipleAnswers ? 0 : 1;
            input.responseRequired = 0;

            // Format Choices to match  the API
            let answers: string[] = [];
            let fraction: number[] = [];
            let feedback: string[] = [];

            let currentValidAnswers = 0;
            data.choices.forEach((item) => {
                if (item.isAnswerValid) {
                    currentValidAnswers++;
                }
            });

            data.choices.forEach((item) => {
                answers.push(item.choiceText);
                fraction.push(item.isAnswerValid ? 1/currentValidAnswers : 0);
                feedback.push(item.feedbackText);
            });

            input.answer = answers;
            input.fraction = fraction;
            input.feedback = feedback;
        } else {
            // Essay
            input.single = 1; // 1 means true, 0 means false
            input.approved = 0;
            input.shuffleAnswers = 0;
            input.answerNumbering = `abc`; // Filler
            input.responseRequired = data.requireText === '1' ? 1 : 0;
            input.answer = [
                data.answerText
            ];
            input.fraction = [1];
            input.feedback = [''];
        }

        this.questionDetailStore.updateQuestionBankQuestionById(input as UpdateQuestionBankQuestionForAxios);
    }

    @Watch(`questionDetailStore.response`)
    redirectToQuestionBankManagement() {
        if (this.questionDetailStore.response) {
            this.questionStateStore.updateQuestionBankManagementIsModified(true);
            this.$router.push({
                path: `/question_banks`
            });
        }
    }
}
